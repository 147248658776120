import classNames from 'classnames'
import React from 'react'
import {LoadMoreButton} from '../load-more-button'
import {MainListTitle} from '../main-list-title'
import {ListItemContainer} from '../event-container/list-item-container'
import {useWidgetState} from '../../hooks/state-provider'
import {getComponentConfig, getListStripWidth} from '../../selectors/settings'
import {hasMoreEvents} from '../../selectors/events'
import s from './list-layout.scss'
import {DesktopItem} from './item'
import {ListLayoutProps} from '.'

export const ListLayout = ({events, t, narrow, mobile, listLayout}: ListLayoutProps) => {
  const hasMore = useWidgetState(hasMoreEvents)

  const style: React.CSSProperties = {
    padding: narrow ? '0 20px' : '0 30px',
    overflowY: narrow && !mobile ? 'scroll' : 'hidden',
    height: narrow && !mobile ? '100%' : 'auto',
  }
  const className = classNames(s.container, s.scrollBarColor, mobile && !listLayout ? s.cardContainer : s.listContainer)

  const componentConfig = useWidgetState(getComponentConfig)
  const stripWidth = getListStripWidth(componentConfig, false)

  return (
    <div className={className} style={style} data-hook="ev-list-layout">
      <MainListTitle />
      <ul>
        {events.map((event: ExtendedEvent, index: number) => (
          <ListItemContainer
            key={event.id}
            event={event}
            hideDivider={events.length - 1 === index && !hasMore}
            stripWidth={stripWidth}
          >
            {hovered => <DesktopItem event={event} hovered={hovered} t={t} />}
          </ListItemContainer>
        ))}
      </ul>
      <LoadMoreButton />
    </div>
  )
}
