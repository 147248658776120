import classNames from 'classnames'
import React from 'react'
import {Animated} from '../../../../../../../commons/components/animated'
import {FullDateLocation} from '../../../full-date-location'
import {Ribbon} from '../../../ribbon'
import {RsvpButton} from '../../../rsvp-button'
import {Image} from '../../common/image'
import {isMobile, isSite} from '../../../../../../../commons/selectors/environment'
import {useWidgetState} from '../../../../hooks/state-provider'
import {isItemOpened} from '../../../../selectors/layout'
import {
  isAdditionalComponentsHidden,
  isDateVisible,
  isDescriptionVisible,
  isImageVisible,
  isMembersVisible,
  isSocialShareVisible,
  isSquareImage,
} from '../../../../selectors/list-settings'
import {getComponentConfig, isListLayout} from '../../../../selectors/settings'
import {getRibbonData} from '../../../../selectors/events'
import {AdditionalInfoToggle} from './additional-info-toggle'
import {Title} from './title'
import {Share} from './share'
import {Members} from './members'
import {Description} from './description'
import {Date} from './date'
import s from './cards-mobile-item.scss'

export const CardsMobileItem = ({event, hovered}: {event: ExtendedEvent; hovered: boolean}) => {
  const opened = useWidgetState(state => isItemOpened(state, event.id))
  const mobile = useWidgetState(isMobile)
  const showImage = useWidgetState(isImageVisible)
  const showDate = useWidgetState(isDateVisible)
  const showSocialShare = useWidgetState(isSocialShareVisible)
  const showMembers = useWidgetState(state => isMembersVisible(state, event))
  const listLayout = useWidgetState(state => isListLayout(getComponentConfig(state)))
  const squareImage = useWidgetState(state => isSquareImage(state, true))
  const listShowDescription = useWidgetState(isDescriptionVisible)
  const additionalComponentsHidden = useWidgetState(isAdditionalComponentsHidden)
  const showRibbon = useWidgetState(state => getRibbonData(state, event).visible)
  const animates = useWidgetState(isSite)

  return (
    <>
      {showImage && (
        <div className={classNames(s.image)}>
          <Image event={event} squareImage={squareImage} />
        </div>
      )}
      {showRibbon ? <Ribbon event={event} className={s.ribbon} /> : null}
      {showDate ? <Date event={event} /> : null}
      <Title event={event} />
      {additionalComponentsHidden ? null : (
        <AdditionalInfoToggle opened={opened} active={!mobile && (hovered || opened)} />
      )}
      <Animated disabled={!animates} show={opened && !additionalComponentsHidden} height={calculateContentHeight}>
        <div className={classNames(s.content, listLayout ? s.listContentText : s.mobileCardsContentText)}>
          <FullDateLocation event={event} />
          {showMembers ? <Members event={event} /> : null}
          {listShowDescription ? <Description event={event} /> : null}
          {showSocialShare ? <Share event={event} /> : null}
        </div>
      </Animated>
      <div style={{marginTop: opened ? 24 : 30, width: '100%'}}>
        <RsvpButton event={event} fullWidth={mobile} className={s.buttonFont} />
      </div>
    </>
  )
}

const calculateContentHeight = (element: HTMLElement) => element.offsetHeight + 60
