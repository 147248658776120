import classNames from 'classnames'
import React from 'react'
import {Animated} from '../../../../../../commons/components/animated'
import sc from '../../classes.scss'
import {FullDateLocation} from '../../full-date-location'
import {Ribbon} from '../../ribbon'
import {RsvpButton} from '../../rsvp-button'
import s from './desktop-item.scss'
import {Date} from './date'
import {Description} from './description'
import {Image} from './image'
import {Members} from './members'
import {Share} from './share'
import {Title} from './title'
import {TitleLocation} from './title-location'
import {ItemProps, TitleComponentProps} from '.'

export const DesktopItem = ({
  t,
  event,
  opened,
  hovered,
  showRibbon,
  showMembers,
  squareImage,
  listLayout,
  listShowDescription,
  additionalComponentsHidden,
  isSite,
  scheduleTbd,
  rtl,
}: ItemProps) => {
  const active = opened || hovered

  return (
    <>
      <div className={classNames(s.collapsedContainer)}>
        <div className={s.leftPositioning}>
          <div className={s.left}>
            <div className={classNames(s.imageAndDate, sc.row, {[s.collapsed]: active, [s.ltr]: !rtl})}>
              <div className={s.imageAndDateContent}>
                <Image event={event} containerWidth={squareImage ? 80 : 142} squareImage={squareImage} />
                <Date t={t} scheduleTbd={scheduleTbd} event={event} />
              </div>
            </div>
            <a className={s.titleContainer} tabIndex={0} aria-expanded={opened} role="button">
              <TitleComponent
                event={event}
                hovered={hovered}
                opened={opened}
                listLayout={listLayout}
                additionalComponentsHidden={additionalComponentsHidden}
              />
            </a>
          </div>
        </div>
        <div className={s.button}>
          {showRibbon ? <Ribbon event={event} className={s.ribbon} /> : null}
          <RsvpButton event={event} minWidth={100} maxWidth={160} />
        </div>
      </div>
      <Animated disabled={!isSite} height={calculateContentHeight} show={opened && !additionalComponentsHidden}>
        <div className={classNames(s.textContent, s.listContentText)}>
          <FullDateLocation event={event} />
          <Members event={event} visible={showMembers} />
          <Description event={event} visible={listShowDescription} />
          <Share t={t} event={event} />
        </div>
      </Animated>
    </>
  )
}

const TitleComponent = ({event, hovered, opened, listLayout, additionalComponentsHidden}: TitleComponentProps) =>
  hovered || opened ? (
    <Title
      active
      event={event}
      opened={opened}
      listLayout={listLayout}
      showArrow={!additionalComponentsHidden}
      withOverflow={!opened}
    />
  ) : (
    <TitleLocation event={event} />
  )

const calculateContentHeight = (element: HTMLElement) => element.offsetHeight + 60
